import React from 'react';

import Options from './Options';
import {assignDefaults} from '../bots/Bot.js';

import useNotifiers from '../Notifiers';

import FraktureTable from '../FraktureTable';

import { useApolloClient } from '@apollo/client';
import {FraktureSimpleQuery} from '../FraktureQuery';
import {ErrorBoundary} from '../Error';

//import {SchemaSelect} from './schemas.js';
import gql from "graphql-tag";
import {NavLink} from 'react-router-dom';
import {withRouter} from '../../util/history.js';
import JobButtons from './JobButtons';
import {AdminOnly} from '../../AdminCheck';
import { JsonViewer } from '@textea/json-viewer';

import ListItem from '@mui/material/ListItem';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import FileLink from "../FileLink";
import SimpleCollapse from "../SimpleCollapse";

import Button from '@mui/material/Button';

import dayjs from 'dayjs';
import reactStringReplace from 'react-string-replace';
import {TabContent,TabContainer} from '../FraktureTabs';

import {TableLink} from '../warehouse/Links';

const jobQuery=gql(`
query JOB($id: ID!) {
	job(_id: $id){
		_id
		can_control
    job_list_id
		context_id
		label
		options
		resolved_options
    log_url
		start_after_timestamp
		start_after_job{
			_id
			label
		}
		start_after_job_id
		bot{
      _id
			path
      label
			account_id
			location{
				label
			}
      definition{
				_id
  			metadata {
  			  alias
  			  bot_path
  			  picture
  			  thumbnail
  			  isWarehouse
  			  iso_picture
  			  logo
  			  channel
  			}
        methods{
            name
            options{
                name
				type
                description
                required
                default
            }
        }
        submodules{
          name
          methods{
              name
              options{
                  name
				  type
                  description
                  required
                  default
              }
          }
        }
  		}
    }
		submodule
		method
		completed_at
		created_at
		modified_at
		progress
		output
		status
		errors
		checkpoints
		metrics{
			http_counter
			sql_counter
			records
			runtime
		}
	}
}`);


const JOB_RESET_CHECKPOINT=gql(`mutation job_kill($_id: ID!,$start_index:Int) {
			job_reset_checkpoint(_id: $_id,start_index:$start_index) {
			  _id
			  checkpoints
				resolved_options
			}
		}`);


function Output({output:_o,bot}){
	let output=_o;
	if (typeof _o=='string'){
		try{
			output=JSON.parse(_o);
		}catch(e){}
	}else{
		output=JSON.parse(JSON.stringify(_o));
	}

	let displays=[
		<TabContent key="raw" label='Output'><JSONDisplay data={output} bot_id={bot._id}/></TabContent>
	];
	for (let i in output){
		if (Array.isArray(output[i].value)){
			let arr=output[i].value.map(d=>{
				for (let j in d){
					if (typeof d[j]=='object') d[j]=JSON.stringify(d[j]);
				}
				return d;
			});
			displays.push(<TabContent key="table" label={output[i].name}>
				<ErrorBoundary><FraktureTable rows={arr}/></ErrorBoundary>
			</TabContent>);
		}
	}
	return <Grid item xs={12} md={12} style={{fontSize:"-1em"}}>
		<h3>Output</h3>
		<TabContainer>
			{displays}
		</TabContainer>
	</Grid>;
}

function JSONDisplay(props){
	let {data,bot_id}=props;
	if (!data) return "";
	if (!bot_id) return "(no bot_id specified)";
	if(typeof data == 'string') {
		try {
			data = JSON.parse(data);
		} catch(e) {
			console.error(e);
		}
	}
	if (!Array.isArray(data)){
		data=Object.keys(data).map(k=>{return {name:k,value:data[k]};});
	}
	let googleIdMatch=/^[0-9a-z_-]{16,}$/i;
	function getVal(o){
		if (!o.value){
			return "";
		}else if (typeof o.value=='object'){
			return (Array.isArray(o.value) && o.value.length===0)?"[]":<JsonViewer enableClipboard={false} displayDataTypes={false} value={o.value} name={null} collapsed={1}/>;
		}else if (typeof o.value=='string'){
			if (o.name==="filename" || o.value.indexOf("/tmp/")===0 || o.value.indexOf("/tmp2/")===0 || o.value.indexOf("/var/folders/")===0){ //supports linux and os x tmp files
				return <FileLink bot_id={bot_id} filenames={o.value}/>;
			} else if(o.name==='table' || o.name.slice(-'_table'.length) === '_table') {
				return <TableLink bot_id={bot_id} table={o.value} />;
			}else if (o.name==='parent_id' && o.value.match(googleIdMatch)){
				return <a target="_blank" href={"https://drive.google.com/drive/u/0/folders/"+o.value}>{o.value}</a>;
			}else if (o.name==='fileId' && o.value.match(googleIdMatch)){
				return <a target="_blank" href={"https://docs.google.com/spreadsheets/d/"+o.value}>{o.value}</a>;
			}
			return o.value.toString();
		}else{
			return o.value.toString();
		}
	}

	return <div>{data.map((o,i)=>{
		if (o.name==="metadata") return null; //ignore metadata
		return <div key={i}><b>{o.name}</b>:
			{getVal(o)}
		</div>;
	}).filter(Boolean)}</div>;
}
function JobErrors(props){
	const job=props.job;
	if(!job || !(job.errors||[]).length) return null;
	if (!job || !job.bot || !job.bot._id) return "Error listing: No bot._id found in job";
	return job.errors.map((e,i)=>{
		try{e=JSON.parse(e);}catch(e){};
		let content=(e.message || JSON.stringify(e)||"").toString();
		if (e.level==='authentication'){
			return <div key={i} className="alert alert-danger">Authentication Error
			</div>;
		}
		content=reactStringReplace(content,/(\/(?:tmp|var\/folders)\/[a-z0-9_./-]*)/g,(file)=>{
			return <FileLink bot_id={job.bot._id} filenames={file}/>;
		});

		return <div key={i} className="alert alert-danger">
			{content}
		</div>;
	});
};

const JobDisplay=withRouter(function _JobDisplay(props) {
	const {notify,notifyErr} = useNotifiers();
	const {job_id}=props;
	const client=useApolloClient();
	if (!job_id) return "No job specified";

	return <ErrorBoundary>
		<FraktureSimpleQuery query={jobQuery} variables={{id:job_id}}>
			{(data) => {
				let job = data;
				if (job.job) job=job.job;
				if (!job){
					console.error("No job found in :",data);
					return "No job found:"+job_id;}
				if (!job.bot){
					console.log(job);
					return "There was no bot found in the cache for this job";
				}
				if (!job._id) return "No job._id";

				const bot=assignDefaults(job.bot);
				let slimJob=JSON.parse(JSON.stringify(job));
				delete slimJob.bot.definition;

				let methods=job.bot.definition.methods;
				if (job.submodule){
					methods=(job.bot.definition.submodules||[]).filter(s=>s.name===job.submodule)[0];
					if (!methods) throw new Error("Could not find submodule "+job.submodule);
					methods=methods.methods;
				}
				if (!methods) throw new Error("Could not find list of methods for submodule "+job.submodule);
				let method=methods.filter(m=>m.name===job.method)[0];
				if (!method){
					console.log("Methods=",methods);
					throw new Error("Could not find method:"+job.method);
				}

				/*if (job.start_after_job){
					prerequisites.push(<span key="saj" className="p-1">Job <u><button className="anchor"
						onClick={e=>navigateToJobList({
							job_list_id:job.job_list_id,
							job_id: job.start_after_job._id
						})}>{job.start_after_job.label}</button></u>
					</span>);
				}
				*/
				let subheader=[
					<NavLink className="" key="link" to={`/app/${bot.account_id}/bot/${bot._id}`}>{[bot.label,job.submodule].filter(Boolean).join(".")}</NavLink>
				];
				let prerequisites=[];
				if (job.start_after_timestamp){
					prerequisites.push(<span key="sat">{dayjs(job.start_after_timestamp).format("MMM DD, HH:mm A z")}</span>);
				}
				if (prerequisites.length>0){
					subheader=subheader.concat(<div key="sub" className="prereq">Starting after {prerequisites}</div>);
				}
				if (job.modified_at)subheader.push(<div className="" key="mod">Modified {(job.modified_at?dayjs(job.modified_at).format("MMM DD, HH:mm A z"):"") }</div>);

				let resolved_options=job.resolved_options;
				let resolvedDisplay=null;
				if (!resolved_options){
					return "No resolved options, this is a bug in the options themselves";
				}
				if (resolved_options){
					if (typeof resolved_options=='string'){
						try{
							resolved_options=JSON.parse(resolved_options);
						}catch(e){
							resolved_options=JSON.stringify(resolved_options);
						}
					}

					if (resolved_options.has_error){
						console.error("Error with options:",resolved_options);
						resolvedDisplay=<div className="alert alert-danger" role="alert">{resolved_options.message}</div>;
					}else{
						resolvedDisplay=<JSONDisplay data={resolved_options} bot_id={bot._id}/>;
					}
				}
				let log_url=job.log_url;
				if (job.bot?.path?.indexOf('engine9')>=0){
					log_url=`https://data.engine9.io/log/job?accountId=${job.bot.account_id}&jobId=${job._id}`;
				}
				return (<Card className={`p-0 m-0`}>
					<CardHeader
						title={job.label}
						className={`status-${job.status}`}
						subheader={<div className="d-flex justify-content-between flex-row">{subheader}</div>}
						action={
							<Grid item xs={12} md={12}>
								<div className="text-right">
									<div key="stat" className="badge badge-light">{job.status}</div>
									{log_url?<a target="_blank" rel="noopener noreferrer" href={log_url+`&start=-50000`}>Log</a>:""}
									{job.bot && job.bot.location?<div style={{fontSize:".7em"}}>{job.bot.location.label}</div>:""}
								</div>
							</Grid>} />
					<CardContent>
						<Grid container spacing={1}>
							<AdminOnly>
								<Grid item xs={12} md={12}>
									<JobButtons job_id={job_id} status={job.status} start_after_job={job.start_after_job} can_control={job.can_control}/>
								</Grid>
							</AdminOnly>
							<Grid item xs={12} md={12}>
								<JobErrors job={job}/>
								{((job.status==="started" || job.status==='pending') && job.progress)?<div className="alert">{job.progress.message}</div>:""}
							</Grid>
							<Grid item xs={12} md={12}>
								<SimpleCollapse title={<h3>Options</h3>}>
									<Options job_id={job_id} options={method.options||[]} values={job.options || {}}/>
									{(job.checkpoints && job.checkpoints.length>0)?<div>
										<h3>Checkpoints</h3>
										<Button color="secondary" size="small" variant="outlined" onClick={e=>client.mutate({mutation:JOB_RESET_CHECKPOINT,variables:{_id:job._id}}).then(notify,notifyErr)}>Reset all</Button>
										{job.checkpoints.map((c,i)=>{
											return <ListItem key={i} className="justify-content-between">
												<JSONDisplay key={i} data={c.options} bot_id={bot._id}/>
												{i<job.checkpoints.length-1?<Button color="secondary" size="small" variant="outlined" onClick={e=>client.mutate({mutation:JOB_RESET_CHECKPOINT,variables:{_id:job._id,start_index:i+1}}).then(notify,notifyErr)}>Reset to here</Button>:""}
											</ListItem>;
										})}
									</div>:""}
									<h3>Resolved Options</h3>
								</SimpleCollapse>
								{resolvedDisplay}
							</Grid>
							<ErrorBoundary>
								{(job.status==="complete" && job.output)?<Output bot={bot} output={job.output}/>:""}
							</ErrorBoundary>
						</Grid>
					</CardContent>
					<SimpleCollapse title="Full job">
						<pre style={{maxWidth:800}}>{JSON.stringify(slimJob||{},null,4)}</pre>
						<hr/>
						<div>bot -a {job.bot.account_id} {job.bot.path}{job.submodule?`.${job.submodule}`:""} {job.method} --bot_id={job.bot._id} {Object.keys(job.resolved_options).filter(d=>job.resolved_options[d] && (typeof job.resolved_options[d]!='object') && ["bot_id"].indexOf(d)<0).map(d=>`--${d}="${job.resolved_options[d]}"`).join(" ")}</div>
					</SimpleCollapse>
				</Card>);

			}}
		</FraktureSimpleQuery>
	</ErrorBoundary>;
});



export {JobDisplay};
