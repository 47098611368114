/* eslint-disable no-restricted-globals */
import React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import StubMutation from "../StubMutation";
import useNotifiers from '../Notifiers';
import gql from "graphql-tag";
import {getStringArray} from "../formatters";
import {AdminOnly} from '../../AdminCheck';
import { useApolloClient } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faTrashArrowUp,faRotateLeft,faPlay,faPause} from '@fortawesome/free-solid-svg-icons';


/*********** Buttons **************/

const JOB_RETRY=gql(`mutation job_retry($_id: ID!,$force:Boolean) {
	job_retry(_id: $_id,force:$force) {
	  _id
	  status
		errors
	}
}`);

const JOB_PAUSE=gql(`mutation job_pause($_id: ID!) {
	job_pause(_id: $_id) {
	  _id
	  status
		errors
	}
}`);
const JOB_KILL=gql(`mutation job_kill($_id: ID!) {
	job_kill(_id: $_id) {
	  _id
	  status
		errors
	}
}`);

/*

<Button variant="contained" color="primary" className="jr-btn">
                    <i className="zmdi zmdi-mic zmdi-hc-fw"/>
                    <span>Icon Button</span>
                </Button>
<ButtonGroup vertical={false}>
		<Button className="jr-btn bg-secondary text-white">Left</Button>
		<Button className="jr-btn bg-secondary text-white">Middle</Button>
		<ButtonDropdown isOpen={this.state.dropDownOpen} toggle={this.toggle}>
			<DropdownToggle className="jr-btn border-0 no-shadow bg-secondary text-white" caret>
				Right
			</DropdownToggle>
			<DropdownMenu>
				<DropdownItem>Dropdown Link</DropdownItem>
				<DropdownItem>Dropdown Link</DropdownItem>
			</DropdownMenu>
		</ButtonDropdown>
	</ButtonGroup>

*/

const JOBLIST_ARCHIVE=gql(`mutation job_list_archive($_ids: [ID]!) {
		job_list_archive(_ids: $_ids)
}`);

const JOBLIST_UNARCHIVE=gql(`mutation job_list_unarchive($_ids: [ID]!) {
		job_list_unarchive(_ids: $_ids)
}`);

function ArchiveButton(props){
	const {notify,notifyErr}=useNotifiers();

	return <StubMutation mutation={JOBLIST_ARCHIVE} refetchQueries={["ALL_JOBLIST_QUERY"]}>
		{(mutate) => {
			return <Tooltip title="Archive Selected"><Button onClick={(e)=>{
				let job_list_ids=getStringArray(props.getJobListIds());
				if (job_list_ids.length===0) return "";
				e.stopPropagation();
				if (confirm("Archive "+job_list_ids.length+(job_list_ids.length>1?" joblists?":" joblist?"))) {
					mutate({variables:{_ids:job_list_ids}}).then(result =>{
						notify((job_list_ids.length>1?"Joblists":"Joblist")+ " archived");
						if (typeof props.onCompleted=='function') props.onCompleted(result);
					},notifyErr);
					return false;}
			}}>
				{props.component || <FontAwesomeIcon icon={faTrash} fixedWidth/>}
			</Button></Tooltip>;
		}}
	</StubMutation>;
}

function UnarchiveButton(props){
	const {notify,notifyErr}=useNotifiers();
	let job_list_ids=getStringArray(props.getJobListIds());
	if (job_list_ids.length===0) return "";
	let component=props.component || <span><FontAwesomeIcon icon={faTrashArrowUp} fixedWidth/></span>;
	return <AdminOnly><StubMutation mutation={JOBLIST_UNARCHIVE} refetchQueries={["ALL_JOBLIST_QUERY"]}>
		{(mutate) => {
			return <Tooltip title="Unarchive"><Button onClick={(e)=>{
				e.stopPropagation();
				if (confirm("Unarchive "+job_list_ids.length+(job_list_ids.length>1?" joblists?":" joblist?"))) {
					mutate({variables:{_ids:job_list_ids}})
						.then(result =>{
							notify((job_list_ids.length>1?"Joblists":"Joblist")+ " unarchived");
							if (typeof props.onCompleted=='function') props.onCompleted(result);
						},notifyErr);
					return false;}
			}}>
				{component}
			</Button></Tooltip>;
		}}
	</StubMutation></AdminOnly>;
}

const JOBLIST_RETRY=gql(`mutation job_list_retry($_ids: [ID]!) {
		job_list_retry(_ids: $_ids)
}`);
function RetryButton(props){
	const {notify,notifyErr}=useNotifiers();

	let component=props.component || <span><FontAwesomeIcon icon={faRotateLeft} fixedWidth/></span>;
	return <StubMutation mutation={JOBLIST_RETRY}>
		{(mutate) => {
			return <Tooltip title="Retry Selected"><Button onClick={(e)=>{
				let job_list_ids=getStringArray(props.getJobListIds());
				if (job_list_ids.length===0) return "";
				e.stopPropagation();
				if (confirm("Restart "+job_list_ids.length+(job_list_ids.length>1?" joblists?":" joblist?"))) {
					mutate({variables:{_ids:job_list_ids}}).then(result =>{
						notify((job_list_ids.length>1?"Joblists":"Joblist")+ " restarted");
						if (typeof props.onCompleted=='function') props.onCompleted(result);
					},notifyErr);
					return false;
				}
			}}>
				{component}
			</Button></Tooltip>;
		}}
	</StubMutation>;
}

export default function JobButtons(props) {
	const client = useApolloClient();
	let {job_id,status,start_after_job,can_control,variant="contained"}=props;
	if(!can_control) return '';

	let buttons=[];

	let forceStartButton=<Button key='force_start' onClick={e => {
		client.mutate({
			mutation: JOB_RETRY,
			refetchQueries:["DATAFLOW_LIST","DATAFLOW_DETAILS"],
			variables:{_id:job_id,force:true}
		});
	}} variant={variant} className="bg-green text-nowrap  text-white" aria-label="Start">
		<span><FontAwesomeIcon icon={faPlay} fixedWidth/> Run Now</span>
	</Button>;

	let startButton=<Button key='start' onClick={(e)=>{
		client.mutate({
			mutation:JOB_RETRY,
			refetchQueries:["DATAFLOW_LIST","DATAFLOW_DETAILS"],
			variables:{_id:job_id}
		});
	}} variant={variant} className="bg-green text-nowrap text-white" aria-label="Start">
		<span><FontAwesomeIcon icon={faPlay} fixedWidth/> Run After Previous Job</span>
	</Button>;

	let pauseButton=<Button key='pause' onClick={(e)=>{
		client.mutate({
			mutation:JOB_PAUSE,
			refetchQueries:["DATAFLOW_LIST","DATAFLOW_DETAILS"],
			variables:{_id:job_id}
		});
	}} variant={variant} className='bg-amber text-nowrap text-white' aria-label="Start">
		<span><FontAwesomeIcon icon={faPause} fixedWidth/> Pause</span>
	</Button>;

	let killButton=<Button key='kill' onClick={(e)=>{
		client.mutate({
			mutation:JOB_KILL,
			variables:{_id:job_id,force:true}
		});
	}} variant={variant} className="bg-red text-nowrap text-white" aria-label="Start">
		<span><i className="zmdi zmdi-close"/> Kill</span>
	</Button>;

	if (["pending","error","complete","paused","killing"].indexOf(status)>=0){
		if (start_after_job){
			buttons.push(startButton);
			buttons.push(forceStartButton);
		}else{
			buttons.push(forceStartButton);
		}
	}

	if (["started","sent_to_queue"].indexOf(status)>=0){
		buttons.push(killButton);
	}

	if (["pending","complete","error","kill_sent"].indexOf(status)>=0){
		buttons.push(pauseButton);
	}

	return <ButtonGroup>{buttons}</ButtonGroup>;
};

export {ArchiveButton,UnarchiveButton,RetryButton,JobButtons};
