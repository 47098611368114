import React from 'react';
import StubMutation from "../StubMutation";
import gql from "graphql-tag";
import {AdminOnly} from '../../AdminCheck';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd} from '@fortawesome/free-solid-svg-icons';
import {useNavigate} from 'react-router-dom';
import useNotifiers from '../Notifiers';


const createDataflowMutation=gql(`mutation createDataflow($account_id:ID!) {
	dataflow_create(account_id: $account_id) {
		_id
		label
		account_id
	}
}`);

export default function NewDataflowButton(props) {
	const {account_id}=props;
	const {notify}=useNotifiers();

	//If no account is specified, just return
	if (!account_id) return "";
	const navigate=useNavigate();

	return (
		<AdminOnly>
			{() => <StubMutation mutation={createDataflowMutation}
				refetchQueries={["DATAFLOW_LIST"]}
				onCompleted={({dataflow_create}) => {
					const{_id}=dataflow_create;
					navigate('/app/'+account_id+'/dataflow/'+_id);
					notify("Created dataflow");
				}}>
				{(create) => <Tooltip title="Add New Dataflow">
					<span><FontAwesomeIcon key="back" onClick={() => create({variables:{account_id}})} icon={faAdd} fixedWidth/></span>
				</Tooltip>
				}
			</StubMutation>}
		</AdminOnly>
	);
}
