import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import { Link} from "react-router-dom";

import Cookies from 'universal-cookie';

import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import {useAccountInfo,useAccountId} from '../account/AccountInfo';
import './styles.scss';

import AccountPicker from '../account/AccountPicker';
import {useAccountMenuItems,useAgencyMenuItems,SidebarMenuItem}from './Menu';
import {Helmet} from "react-helmet-async";
import {AdminOnly} from '../../AdminCheck';
import AdminLinks from './AdminLinks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompass,faBars} from '@fortawesome/free-solid-svg-icons';

import queryString from 'query-string';
import useMediaQuery from '@mui/material/useMediaQuery';

export function MenuToggle(){
	const [open, setOpen] = React.useState(false);
	const isWide = useMediaQuery('(min-width:1024px)');
	if (isWide) return false;
	return <React.Fragment>
		<Button onClick={()=>setOpen(true)}><FontAwesomeIcon icon={faBars}/></Button>
		<Drawer
			anchor={'left'}
			open={open}
			onClose={()=>setOpen(false)}
		>
			<Sidebar className="mobile-drawer"/>
		</Drawer>
	</React.Fragment>;
}

const AgencyMenu=function AgencyMenu({account_id}){
	const {account,loading,error}=useAccountInfo({account_id});
	const agencyItems=useAgencyMenuItems({account_id});

	if (loading){
		console.log("Reloading agency menu for account "+account_id);
		return null;
	}
	if (error) return "Pick an account";
	if (!account || !account.name){
		return null;
	}
	let children=JSON.parse(JSON.stringify((account.children||[]).filter(d=>!d.disabled)))
		.map(d=>{
			let [href,hash]=document.location.href.split("#");
			let parts=href.split('/');
			parts[4]=d._id;
			let path="/"+parts.slice(3).join("/");
			if (hash){
				let attr=queryString.parse(hash);
				delete attr.message_id;
				path+="#"+queryString.stringify(attr);
			}
			return {label:d.name,path};
		});
	return <React.Fragment><List>
		<Divider/>
		<SidebarMenuItem label={"Parent: "+account.name} path={`/app/${account_id}`} exactPath={true}/>
		{agencyItems.map((d,i)=><SidebarMenuItem key={i} {...d}/>)}
	</List>
	{children.length ? <SidebarMenuItem icon={faCompass} label="Child Accounts" children={children} open={false}/>:""}
	</React.Fragment>;
};


function Menu(){
	const {account,loading,error}=useAccountInfo();
	const items=useAccountMenuItems();
	if (loading) return null;
	if (error) return <List>
		<ListItem
			secondaryAction={
				<IconButton edge="end" aria-label="pick-account">
					<AccountPicker className="search-icon"/>
				</IconButton>
			}
		>
			<ListItemText
				primary="Pick an account"
			/>
		</ListItem>
	</List>;

	let parent_id=account?.parents?.[0]?._id;
	if (parent_id==='frakture_master') parent_id=null;

	return <React.Fragment>
		<Helmet><title>{account?account.name+" - ":""} - Frakture</title></Helmet>
		<List>
			<ListItem
				secondaryAction={
					<IconButton edge="end" aria-label="pick-account">
						<AccountPicker className="search-icon"/>
					</IconButton>
				}
			>
				<ListItemText
					primary={account?<Link className="sidebar-link sidebar-account-name" to={`/app/${account._id}`}>{account.name}</Link>:"Pick an account"}
					secondary={account?.disabled?"(disabled)":null}
				/>
			</ListItem>
			{account && items.map((d,i)=><SidebarMenuItem key={i} {...d}/>)}</List>
		{parent_id?<AgencyMenu account_id={parent_id}/>:((account?._id!=='frakture_master' && account?.children?.length)?<AgencyMenu account_id={account._id}/>:"")}
		<AdminOnly><Divider/><AdminLinks/></AdminOnly>
	</React.Fragment>;
}



function Sidebar({className}){
	const account_id=useAccountId();
	const cookies = new Cookies();
	let cookieOpen=!(cookies.get('sidebar-open')==='0');

	const [open, setOpen] = React.useState(cookieOpen);
	function toggleSide(){
		let openVal=open?'0':'1';
		cookies.set('sidebar-open', openVal, { path: '/' });
		setOpen(!open);
	}
	if(false)toggleSide();

	let homeLink="/app"+(account_id?"/"+account_id:"");

	return <div id="sidebar" className={`${className||''}${open?" no-print is-expanded":" no-print"}`}>
		<div className="sidebar-content">
			<div className="sidebar-header">
				<Link className="app-logo" to={homeLink}>
					<img className="logo-large" src="/images/FRAKTURE_Logo2022_TypeOnly_Color-BlackType.png" alt="Frakture" title="Frakture"/>
					<img className="logo-small" src="/images/icon_black_64.png" alt="Frakture" title="Frakture"/>
				</Link>
				{/*<div className="sidebar-collapse-button" onClick={toggleSide}>{open?<FontAwesomeIcon icon={faArrowLeftToLine} fixedWidth/>:<FontAwesomeIcon icon={faArrowRightToLine} fixedWidth/>}</div>*/}
			</div>
			<Menu/>
		</div>
	</div>;
}
export default Sidebar;
//export default React.memo(Sidebar); //Hmm -- memo is actually causing it to be RELOADED, instead of not reloaded ... weird
