/* eslint-disable no-restricted-globals */
import React from 'react';
import {Route, Routes} from 'react-router-dom';

import JobPanel from './job/index.js';

//import {isIOS, isMobile} from 'react-device-detect';

import {useLocation} from 'react-router-dom';

import AccountList from './constellation/AccountList';

import Account from './account';
import Users from './users';


function Content(){
	return <Routes>
		<Route path='*' element={<AccountList/>} />
		<Route path='users' element={<Users/>}/>
		<Route path='users/*' element={<Users/>}/>
		<Route path=':account_id/*' element={<Account/>}/>
		<Route path=':account_id/multiaccount/*' element={<AccountList/>} />
		<Route path=':account_id/multiaccount/job/*' element={<div className="app-main-content-wrapper"><JobPanel/></div>} />
		<Route path=':account_id/multiaccount/job/:job_list_id/*' element={<div className="app-main-content-wrapper"><JobPanel/></div>} />
		<Route path=':account_id/multiaccount/job/:job_list_id/:job_id/*' element={<div className="app-main-content-wrapper"><JobPanel/></div>} />
	</Routes>;
}

/***** v2 code */

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

//import Container from '@mui/material/Container';


import Sidebar from './sidebar';
import useMediaQuery from '@mui/material/useMediaQuery';

export function DashboardV2() {
	const isWide = useMediaQuery('(min-width:1024px)');
	const url = (window.location != window.parent.location)
		? document.referrer
		: document.location.href;

	const sidebar=(
		isWide
	&& url.indexOf('engine9.io')<0);
	//debugger;

	return (<>
		<div id="background"></div>
		<Box id="app-container" component="main" sx={{ display: 'flex' }}>
			<CssBaseline />
			{sidebar&&<Sidebar/>}
			<Content/>
		</Box>
	</>
	);
}
