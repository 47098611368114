/* eslint-disable no-restricted-globals */
import React,{useState} from 'react';
import gql from "graphql-tag";

import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';

import {CSS} from '@dnd-kit/utilities';
import {
	useSortable,
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';


import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import ReorderIcon from '@mui/icons-material/Reorder';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft,faTrash} from '@fortawesome/free-solid-svg-icons';

import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import {useNavigate} from 'react-router-dom';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import {useAccountId} from '../../account/AccountInfo';
import NewJobSelector from './NewJobSelector';
import JobEditor from './JobEditor';
import LabelEdit from '../../inputs/LabelEdit';
import Tooltip from '@mui/material/Tooltip';
import JobListSmallCard from '../../job/JobListSmallCard';

import useNotifiers from '../../Notifiers';
import { useApolloClient } from '@apollo/client';
import {AdminOnly} from '../../../AdminCheck';

import ScheduleSelect from '../../inputs/ScheduleSelect';
import DisabledSwitch from './DisabledSwitch';
import {FraktureSimpleQuery} from '../../FraktureQuery';


import ParentSelect from './ParentSelect';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ChildDataflowEditor from './ChildDataflowEditor';
import fragments from '../gql-fragments';
import DataflowChildren from './DataflowChildren';
import DataflowConfig from './DataflowConfig';

import {TabContent,TabContainer} from '../../FraktureTabs';
import RunButton from './RunButton';

const DATAFLOW_DETAILS= gql`query DATAFLOW_DETAILS($dataflow_id:ID!,$account_id:ID!) {
	account(_id:$account_id){
		shared_dataflows{
		_id
			label
		account{
		  _id
			name
		}
		}
	}
	dataflow(_id:$dataflow_id) {
		... fullDataflow
	}
}
${fragments.fullDataflow}
`;

const UPDATE_DATAFLOW_MUTATION = gql`mutation UPDATE_DATAFLOW($dataflow_id:ID!, $update: DataflowUpdate!) {
	dataflow_update(_id: $dataflow_id, update:$update) {
		_id
		label
		description
		disabled
		schedule
		parent{
			_id
		}
		... dataflowBotOverrides
	}
}
${fragments.dataflowBotOverrides}
`;

const addJobMutation = gql`mutation addJob($dataflow_id:ID!, $new_job: DataflowNewJob!) {
	dataflow_job_add(_id:$dataflow_id, new_job: $new_job) {
		_id
		jobs {
			... dataflowJob
		}
	}
}
${fragments.dataflowJob}
`;

const deleteJobMutation = gql`mutation deleteJob($dataflow_id:ID!, $context_id: ID!) {
	dataflow_job_delete(_id:$dataflow_id, context_id: $context_id) {
		_id
		jobs {
			... dataflowJob
		}
	}
}
${fragments.dataflowJob}
`;


const moveJobMutation = gql`mutation moveJob($dataflow_id:ID!, $context_id: ID!, $position:Int!) {
	dataflow_set_job_position(_id:$dataflow_id, context_id: $context_id,position:$position) {
		_id
		jobs {
			... dataflowJob
		}
	}
}
${fragments.dataflowJob}
`;

const updateJobMutation = gql`mutation updateJob($dataflow_id:ID!, $context_id: ID!, $update: DataflowJobUpdate!) {
	dataflow_job_update(_id: $dataflow_id, context_id: $context_id, update:$update) {
		_id
		job(context_id: $context_id) {
			... dataflowJob
		}
	}
}
${fragments.dataflowJob}
`;

const updateJobOptionsMutation = gql`mutation updateJobOptions($dataflow_id:ID!, $context_id: ID!, $updates:[DataflowOptionUpdate]) {
	dataflow_job_options_update(_id:$dataflow_id, context_id:$context_id, updates:$updates) {
		_id
		job(context_id: $context_id) {
			... dataflowJob
		}
	}
}
${fragments.dataflowJob}
`;


const deleteDataflowMutation = gql(`mutation deleteDataflow($_id:ID!) {
	dataflow_delete(_id:$_id){
		_id
	}
}`);


/*

const DATAFLOW_JOB_CONFIGURATION_QUERY=gql`
query DATAFLOW_JOB_CONFIGURATION_QUERY($dataflow_id:ID!,$context_id:ID!){
	dataflow(_id:$dataflow_id) {
		account_id
		job(context_id:$context_id) {
			label
			configuration {
				triggers {
					type
					dataflow_id
					trigger_routing
				}
				trigger_map
				notifications
			}
		}
	}
}
`;

function DataflowJobAdvancedSettingsDialog(props) {
	const {dataflow_id,context_id}=props;

	const {data}=useQuery(DATAFLOW_JOB_CONFIGURATION_QUERY,{
		variables: {
			dataflow_id,
			context_id
		}
	});

	let job={};
	if(data) job=data.dataflow.job;
	let account_id;
	if(data) account_id=data.dataflow.account_id;

	const [configuration,setConfiguration]=useState({
		triggers: [],
		trigger_map: '',
		notifications: ''
	});
	useEffect(() => {
		if(job.configuration) setConfiguration(job.configuration);
	},[job.configuration]);

	const dfTrigger = (configuration.triggers||[]).find(x => x.type === 'dataflow') || {
		type: "dataflow"
	};

	const updateDfTrigger = (update) => {
		const updated = Object.assign({},dfTrigger,update);
		let used=false;
		setConfiguration({}, configuration, {
			triggers: configuration.triggers.map(x => {
				if(x.type === 'dataflow') {
					used = true;
					return updated;
				}
				return x;
			}).concat(used ? [] : [updated])
		});
	};

	const [dialog,setDialog]=useState(false);
	return (
		<React.Fragment>
			<IconButton size="large">
				<SettingsIcon onClick={(e) => {
					setDialog(true);
				}} />
			</IconButton>
			<Dialog open={dialog} onClose={() => setDialog(false)}>
				<DialogTitle>
					{job.label} - Advanced Settings
				</DialogTitle>
				<DialogContent>
					<Typography variant="h6" gutterBottom>
						Triggered Dataflow
					</Typography>
					<FormControl fullWidth>
						<DataflowSelect account_id={account_id} value={dfTrigger.dataflow_id}
							onChange={x => updateDfTrigger({dataflow_id:x})} />
					</FormControl>
					<TextField label='Routing' value={dfTrigger.trigger_routing} fullWidth
						onChange={e => updateDfTrigger({trigger_routing:e.target.value})} />
					<TextField label='Trigger Map' value={configuration.trigger_routing}
						fullWidth multiline rows={4} variant="outlined"
						onChange={e => setConfiguration(Object.assign({}, configuration, {trigger_routing:e.target.value}))} />
					<Typography variant="h6" gutterBottom>
						Notifications
					</Typography>
					<TextField fullWidth value={configuration.notifications}
						onChange={e => setConfiguration(Object.assign({}, configuration, {notifications: e.target.value}))} />
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
*/

const SortableJob = (props) => {
	let {dataflow_id,job,removeJob}=props;
	const {
		attributes,
		listeners,
		setNodeRef,
		transform
	} = useSortable({id: job.context_id});

	let context_id=job.context_id;
	const {notify,notifyErr} = useNotifiers();
	const client = useApolloClient();

	const style = {
		transform: CSS.Transform.toString(transform)
	};

	return <Accordion ref={setNodeRef} style={style} className="dataflow-job">
		<AccordionSummary
			expandIcon={<ExpandMoreIcon />}
		><div>
				<Tooltip title={job.bot.label}>
					<img alt="icon" key="icon" src={job.bot.definition.metadata.logo} className="rounded" width="80"/>
				</Tooltip>
				<br/>
			</div>
			<ListItemText className="pl-4 font-weight-bold"
				primary={job.submodule?job.submodule+"."+job.label:job.label}
				secondary={job.method+" - "+job.context_id}
			/>
			<div className="p-3 text-right" style={{fontSize:".8em"}}>
				{Object.keys(job.options).filter(d=>job.options[d]!=="").map(d=>{
					let v=job.options[d];
					if(v && typeof v != 'string') v = JSON.stringify(v);
					if (String(v).length>40){v=v.slice(0,40)+" ...";}
					return <div key={d}>{d}: <b>{v}</b></div>;
				})}
			</div>
			{/*<DataflowJobAdvancedSettingsDialog {...{dataflow_id:dataflow_id, context_id:job.context_id}} />*/}
			<Tooltip title="Delete Job">
				<Button onChange={(e)=>{e.stopPropagation();return false;}} onClick={(e)=>{e.stopPropagation();
					if (confirm("Are you sure you want to delete this job?")){
						client.mutate({mutation:deleteJobMutation,variables:{dataflow_id:dataflow_id,context_id}}).then(notify,notifyErr);
						removeJob(job.context_id);
					}
					return false;
				}} aria-label="Delete Job">
					<FontAwesomeIcon icon={faTrash} fixedWidth/>
				</Button>
			</Tooltip>
			<Tooltip title="Click and drag to move job">
				<IconButton edge="end" aria-label="Sort" size="large" {...listeners} {...attributes}>
					<ReorderIcon />
				</IconButton>
			</Tooltip>
		</AccordionSummary>
		<AccordionDetails TransitionProps={{ unmountOnExit: true }}>
			<JobEditor key={context_id} job={job}
				onOptionSave={({key,value}) => client.mutate({
					mutation: updateJobOptionsMutation,
					refetchQueries:["DATAFLOW_DETAILS"],
					variables: {
						dataflow_id,
						context_id: job.context_id,
						updates: [{key, value:JSON.stringify(value)}]
					}
				}).then(notify,notifyErr)}
				onJobSave={({update}) => client.mutate({
					mutation: updateJobMutation,
					refetchQueries:["DATAFLOW_DETAILS"],
					variables: {
						dataflow_id,
						context_id: job.context_id,
						update
					}
				}).then(notify,notifyErr)}
			/>
		</AccordionDetails>
	</Accordion>;
};


function NewDataflowJob(props) {
	const{dataflow_id}=props;
	const account_id=useAccountId();
	const {notify,notifyErr} = useNotifiers();
	const client=useApolloClient();
	return <NewJobSelector account_id={account_id} addJob={(settings) => {
		const {bot_id,submodule,method}=settings;
		if(!bot_id || !method) throw new Error('Need at least bot_id and method');
		client.mutate({
			mutation: addJobMutation,
			variables:{dataflow_id, new_job: {bot_id,submodule,method}},
			refetchQueries:["DATAFLOW_DETAILS"]
		}).then(out=>{
			let ids=(out?.data?.dataflow_job_add?.jobs||[]).map(d=>d.context_id);
			if (typeof props.setJobIds=='function'){
				console.log("Add setting to "+ids.join());
				props.setJobIds(ids);
			}
			notify();
		},notifyErr);
	}}/>;
}

function StandardDataflowEditor(props) {
	const {notify,notifyErr} = useNotifiers();
	const client = useApolloClient();
	const {dataflow,account_id}=props;
	const jobs = dataflow.jobs;
	const dataflow_id=dataflow._id;
	const [job_ids, setJobIds] = useState(jobs.map(d=>d.context_id));
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	function handleDragEnd(event) {
		const {active, over} = event;
		if (active.id !== over.id) {
			const oldIndex = job_ids.indexOf(active.id);
			const newIndex = job_ids.indexOf(over.id);
			let context_id=active.id;
			let new_ids=arrayMove(job_ids, oldIndex, newIndex);
			setJobIds(new_ids);
			client.mutate({mutation:moveJobMutation,variables:{dataflow_id:dataflow._id,context_id,position:newIndex}}).then(notify,notifyErr);
		}
	}
	function removeJob(context_id){
		let new_ids=job_ids.filter(j=>j!==context_id);
		setJobIds(new_ids);
	}

	return <div>
		<DndContext
			sensors={sensors}
			collisionDetection={closestCenter}
			onDragEnd={handleDragEnd}
		>
			<SortableContext
				items={job_ids}
				strategy={verticalListSortingStrategy}
			>
				{job_ids.map((context_id, index) => {
					let j=jobs.find(d=>d.context_id===context_id);
					if (!j) return "Error, could not find job in list of jobs";
					return <SortableJob key={`item-${index}`} index={index} account_id={account_id} dataflow_id={dataflow_id} job={j} removeJob={removeJob} />;
				})}
			</SortableContext>
		</DndContext>
		<AdminOnly><NewDataflowJob {...{dataflow_id,account_id,setJobIds}} /></AdminOnly>
	</div>;
};

function DeleteButton({dataflow_id,account_id}){
	const client=useApolloClient();
	const {notify,notifyErr} = useNotifiers();
	const navigate=useNavigate();
	if (!account_id) return "No account_id";
	return <Button onClick={(e)=>{
		e.stopPropagation();
		if (confirm("Are you sure you want to delete this dataflow?")){
			client.mutate({
				mutation:deleteDataflowMutation,
				refetchQueries:["DATAFLOW_LIST","DATAFLOW_DETAILS"],
				variables:{_id:dataflow_id}})
				.then(e=>{
					notify("Deleted dataflow");
					navigate("/app/"+account_id+"/job");
				},notifyErr);
			return false;}
	}
	}
	className={`bg-danger text-nowrap text-white`} aria-label="Delete dataflow">
		<i className="zmdi zmdi-delete"/>
		<span>&nbsp;Delete Dataflow</span>
	</Button>;
}


function DataflowEditorHeader(props) {
	const navigate=useNavigate();
	const {notify,notifyErr} = useNotifiers();

	const client=useApolloClient();
	const {dataflow,account_id,dataflow:{_id:dataflow_id}}=props;
	let closeButton=<FontAwesomeIcon key="back" onClick={e=>navigate("/app/"+account_id+"/job")} icon={faArrowLeft} fixedWidth/>;

	let label=<div className="d-flex">
		{closeButton}
		<h3 className="w-100">
			<LabelEdit value={dataflow.label} style={{width:'calc(100% - 20px)'}}
				onChange={(label) => client.mutate({
					mutation: UPDATE_DATAFLOW_MUTATION,
					variables: {
						dataflow_id,
						update:{label}
					}
				}).then(notify,notifyErr)}/>
		</h3>
	</div>;
	let description=<LabelEdit placeholder="Enter description...."
		value={dataflow.description}
		style={{width:'calc(100% - 20px)'}}
		onChange={(description) => client.mutate({ // eslint-disable-line no-shadow
			mutation: UPDATE_DATAFLOW_MUTATION,
			variables: {
				dataflow_id,
				update:{description}
			}
		}).then(notify,notifyErr)}
	/>;

	let scheduleSelect=<ScheduleSelect schedule={dataflow.schedule}
		onChange={(schedule) =>{
			client.mutate({ // eslint-disable-line no-shadow
				mutation: UPDATE_DATAFLOW_MUTATION,
				variables: {
					dataflow_id,
					update:{schedule}
				}
			}).then(notify,notifyErr);}
		}/>;

	return <Card square style={{marginBottom:'15px',boxShadow:"none"}}>
		<CardHeader title={label}/>
		<CardContent>
			<Grid container spacing={1}>
				<Grid item sm={12} md={8} lg={8}>
					{description}
				</Grid>
				<Grid item sm={12} md={2} lg={2}>
					Schedule: {scheduleSelect}
				</Grid>
				<Grid item sm={12} md={2} lg={2}>
					<DisabledSwitch dataflow_id={dataflow_id} disabled={dataflow.disabled}/>
				</Grid>
			</Grid>
		</CardContent>
		<CardActions>
			<AdminOnly><div className="mr-auto"><DeleteButton dataflow_id={dataflow_id} account_id={account_id}/></div></AdminOnly>
			<RunButton dataflow_id={dataflow_id}/>
			<RunButton dataflow_id={dataflow_id} pause_first_job={true}/>
		</CardActions>
	</Card>;
};

function UndeclaredDataflowEditor(props) {
	const{dataflow:{_id:dataflow_id},account:{_id:account_id}}=props;
	return <React.Fragment>
		<Card style={{overflow:'visible'}}>
			<CardContent>
				<p>Either select a parent dataflow or add jobs to create a joblist</p>
				<Grid container spacing={1}>
					<Grid item md={6}>
						<NewDataflowJob {...{dataflow_id,account_id}} />
					</Grid><Grid item md={6}>
						<ParentSelect {...props} />
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	</React.Fragment>;
};

export default function(props){
	const{dataflow_id,context_id}=props;
	const account_id=useAccountId();

	return <FraktureSimpleQuery query={DATAFLOW_DETAILS} variables={{dataflow_id,account_id}}>{(data) => {
		const {parent,jobs,job_lists}=data.dataflow;
		let editor;
		if(parent) editor = <ChildDataflowEditor {...data} account_id={account_id}/>;
		else if(jobs.length) editor = <StandardDataflowEditor {...data} {...{context_id,account_id}} />;
		else editor = <UndeclaredDataflowEditor {...data} account_id={account_id} />;
		job_lists.sort((a,b)=>{
			return a._id.toString()<b._id.toString()?1:-1;
		});

		return <div className="app-main-content p-2">
			<Card>
				<CardContent>
					<DataflowEditorHeader account_id={account_id} {...data} />
					<TabContainer>
						<TabContent label='Edit'>
							{editor}
						</TabContent>
						<TabContent label='Share'>
							<DataflowChildren {...{dataflow_id}}/>
						</TabContent>
						<TabContent label="History">
							{<List subheader={<h3 className="p-2">Recent Joblists</h3>}>{(job_lists.length===0)?"No recent history":""}{job_lists.map(job_list => {
								return <JobListSmallCard show_extended={true} joblist={job_list} job_list_id={job_list._id} key={job_list._id} />;
							})}</List>}
						</TabContent>
						<TabContent label='Config'>
							<DataflowConfig {...{dataflow_id}}/>
						</TabContent>
					</TabContainer>
				</CardContent>
			</Card>
		</div>;
	}}</FraktureSimpleQuery>;
};
