import React,{useState} from 'react';
import Async from 'react-select/async';
import {useNavigate,Link} from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useQuery} from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faArrowAltCircleRight,faMagnifyingGlass,faClose} from '@fortawesome/free-solid-svg-icons';

import gql from "graphql-tag";

const searchQuery=gql(`query searchQuery($limit:Int) {
  accounts(limit:$limit) {
    page{
      _id
			parent_ids
      name
			disabled
      bots{
        _id
        path
      }
    }
   }
}`);


const CustomOption = ({ data,innerProps, isDisabled }) =>{
	return !isDisabled ? (
		<React.Fragment>
			<div className="p-3 flex-column align-items-start" {...innerProps}>
				<div className="d-flex w-100 justify-content-between">
					<h3 className="mb-1">{data.label}</h3>
					<span>{data.disabled?<b className="text-danger">Disabled&nbsp;&nbsp;</b>:""}{(data.parent_ids || []).map((t,i)=><span key={i} className="badge badge-primary">{t}</span>)}</span>
					<Link to={`/app/${data._id}`} onClick={(e)=>e.stopPropagation()} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faArrowAltCircleRight}/></Link>
				</div>
				<p className="mb-1">{(data.bots || []).map(b=>{return b.path.split('.').pop().replace("Bot","");}).join(", ")}</p>
			</div>
			<Divider />
		</React.Fragment>
	) : null;
};


function AccountPickerImpl() {
	const asyncRef = React.useRef(null);
	const navigate = useNavigate();
	const handleChange=(selected) => {
		navigate('/app/'+selected._id);
	};
	const focusAsync = () => {
		asyncRef.current?.focus();
	};

	React.useEffect(() => {
		focusAsync();
	});

	const [allOptions,setAllOptions]=useState([]);
	useQuery(searchQuery, {
		variables: {limit:2000},
		onCompleted: ({accounts}) => {
			const byId = {};
			const options=accounts.page.map((_d)=>{
				let d=Object.assign({},_d);
				d.label=_d.name;
				d.name=(_d.name||"").toLowerCase();
				d.bots=(_d.bots||[]).map(b=>{return Object.assign( {},b,{path_lc:b.path.toLowerCase()}); });
				byId[_d._id]=d;
				return d;
			});
			console.log("Search options:",options);
			setAllOptions(options);
		}
	});

	const loadOptions = (inputValue, callback) => {
		// let start=new Date().getTime();
		if (!inputValue) return callback(allOptions);

		const matches = {};
		const arr=inputValue.toLowerCase().split(/\s+/);

		const resp=allOptions.filter(d=>{
			return arr.every(v=>(
				d.name.indexOf(v)>=0
					|| (d.parent_ids||[]).join(",").indexOf(v)>=0
					|| d.bots.some(b=>b.path_lc.indexOf(v)>=0)
          || d.bots.some(b=>b._id.indexOf(v)>=0)
					|| d._id.indexOf(v)>=0
			));
		});

		resp.filter(x=>x._id === inputValue || x.name.toLowerCase()===inputValue)
			.forEach(x=>matches[x._id]=x);
		resp.filter(x=>!matches[x._id] && (x._id.indexOf(inputValue) === 0 || x.name.toLowerCase().indexOf(inputValue) === 0))
			.forEach(x=>matches[x._id]=x);
		resp.filter(x=>!matches[x._id] && (x.parent_ids||[]).find(y=>y===inputValue))
			.forEach(x=>matches[x._id]=x);
		resp.filter(x=>!matches[x._id])
			.forEach(x=>matches[x._id]=x);

		// console.log(matches);
		const results = Object.values(matches);
		callback(results);
	};
	let isFixed=true;
	/* Portal options are so its not size limited by the parent */
	return <React.Fragment>{allOptions.length===0?"Loading ...":<Async
		ref={asyncRef}
		menuPortalTarget={document.body}
		styles={{ menuPortal: base => ({ ...base,
			zIndex: 9999,
		}) }}
		menuPosition={isFixed ? 'fixed' : 'absolute'}
		menuPlacement={'bottom'}
		cacheOptions
		onChange={handleChange}
		closeMenuOnSelect={false}
		autoFocus={true}
		loadOptions={loadOptions}
		defaultOptions={true}
		classNamePrefix="account-picker"
		components={{ Option: CustomOption }}
		placeholder=""
	/>}
	</React.Fragment>;
}

export default function AccountPicker({className}){
	const [open, setOpen] = React.useState(false);
	/* This is to cache the accounts in the background */
	useQuery(searchQuery, {variables: {limit:2000}});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	return <React.Fragment>
		<FontAwesomeIcon onClick={handleClickOpen} icon={faMagnifyingGlass} fixedWidth className={className}/>
		<Dialog
			className="account-picker-dialog"
			open={open}
			onClose={handleClose}
			fullWidth={true}
			maxWidth={"md"}
		>
			<DialogTitle style={{display:"flex",justifyContent:"space-between"}}><span>Account Search</span><Button onClick={handleClose}><FontAwesomeIcon icon={faClose}/></Button></DialogTitle>
			<DialogContent>
				<Box>
					<AccountPickerImpl/>
				</Box>
			</DialogContent>
		</Dialog>
	</React.Fragment>;
}
