import React from 'react';
import { Route, Routes} from 'react-router-dom';

import Message from './Message';
import MessageSummary from './MessageSummary';
import MessageSheet from './MessageSheet.js';
import MessageSourceCodes from './MessageSourceCodeSheet.js';
import TextField from '@mui/material/TextField';

import {useWarehouseQuery,useConditionsFromQueryString} from '../warehouse/WarehouseQuery';


import {NavLink} from 'react-router-dom';
//import List from '@mui/material/List';
import AutoSizer from "react-virtualized-auto-sizer";
import {SidebarListItem} from '../components/SidebarList';
import {Header} from '../components/Header';
import {useNavigateToMessage} from './Message.js';
import {useAccountId} from '../account/AccountInfo';
import {useLocation} from 'react-router-dom';
import queryString from 'query-string';

import { FixedSizeList as WindowedList } from 'react-window';

import {humanize,humanizeDate,formatValue} from '../formatters';
import MessageFilters from './MessageFilters';

import { faEnvelope,faMessage,faPaperPlane,faNoteSticky} from '@fortawesome/free-solid-svg-icons';
import { faFacebookF,faGoogle,faTwitter} from '@fortawesome/free-brands-svg-icons';
import {useBots} from '../bots/BotList';

import Chip from '@mui/material/Chip';


export function ChannelAvatar({channel}){
	let icon=null;
	switch(channel){
	case 'email': icon=faEnvelope;break;
	case 'google_ad':icon=faGoogle; break;
	case 'facebook_ad':
	case 'facebook': icon=faFacebookF;break;
	case 'promoted_tweet':
	case 'organic_tweet':
		icon=faTwitter;break;
	case 'sms': icon=faMessage;break;
	case 'post': icon=faNoteSticky;break;
	default: icon=faPaperPlane;
	}
	if (false) console.log(icon);
	return <Chip className={`channel-${channel}`} size="small" label={channel}/>;
	//return <FontAwesomeIcon icon={icon} size="lg"/>;
	/*return <Avatar
		sx={{
			bgcolor:"#FFF",
			width,height}}
		alt={channel}
	>
		<FontAwesomeIcon icon={icon}/>
	</Avatar>;
	*/
};


const revenue=function(m){
	let aval=parseFloat(m.attributed_revenue);
	let rval=parseFloat(m.revenue);
	if (aval>0){
		return <span key="revenue" className="attributed_revenue">{formatValue(m.attributed_revenue,"currency")}</span>;
	}else if (rval>0){
		<span key="revenue" className="revenue">{formatValue((m.revenue),"currency")}</span>;
	}else{
		return <span key="revenue"></span>;
	}
};

const MessageItems = ({ data:{messages,selectedId,navToMessage},index,style}) =>{
	const m=messages[index];
	if (!m) return null;
	let {channel}=m;
	return <SidebarListItem
		style={style}
		selected={m.message_id===selectedId}
		primary={[humanize(m.subject||m.label||m.primary_source_code || m.channel,45),<ChannelAvatar key="icon" channel={channel}/>]}
		secondary={m.campaign_name}
		footer={[humanizeDate(m.publish_date),revenue(m)]}
		onClick={e=>navToMessage({message_id:m.message_id})}
	/>;
};
const MessageListWithSearch=({messages})=>{
	let [searchValue,setSearchValue]=React.useState("");
	const navToMessage=useNavigateToMessage();
	const location = useLocation();
	let hash=queryString.parse(location.hash);
	//const [filter] = useDebounce(searchValue, 500);
	let filtered=messages;
	if (searchValue){
		const arr=searchValue.toLowerCase().split(/\s+/);
		filtered=messages.filter(m=>{
			return arr.every(v=>{
				return m.search_terms?.some(d=>d.indexOf(v)>=0);
			});
		});
	}
	/*
	//If we need to sort or somthing, the itemKey is how we specify things
	function itemKey(index, data) {
		// Find the item at the specified index.
		// In this case "data" is an Array that was passed to List as "itemData".
		const item = data[index];
		// Return a value that uniquely identifies this item.
		// Typically this will be a UID of some sort.
		return item._id;
	}
	*/

	return <>
		<SidebarListItem
			key={"message-quick-filter"}
			primary={
				<TextField
					key="message-quick-filter"
					id="message-quick-filter"
					className="w-100"
					variant="standard"
					value={searchValue}
					name="message-quick-filter"
					aria-label="Quick Filter" placeholder="Quick Filter"
					onChange={e=>{setSearchValue(e.target.value);}}
					InputProps={{disableUnderline: true}}
				/>
			}
		/>{filtered.length===0?<div className="p-2">No messages match filters</div>:
			<AutoSizer>
				{({ height, width }) => (<><WindowedList
					height={height-45}
					itemCount={filtered.length}
					itemData={{messages:filtered,navToMessage,selectedId:parseInt(hash.message_id)}}
					//itemKey={itemKey}
					itemSize={140}
					width={width}
				>{MessageItems}</WindowedList></>)}</AutoSizer>}
	</>;
};


function MessageList(props){
	const {default_query}=props;
	const {bots}=useBots();
	const {conditions,loading:loadingConditions,limit}=useConditionsFromQueryString({date_field:"publish_date",default_query});
	let order_by=[{
		fql: "publish_date",
		order_by_direction: "DESC"
	}];
	const table='${global_table_prefix}global_message_summary';

	let fields=("message_id,publish_date,label,subject,channel,bot_id,campaign_name,primary_source_code,revenue,attributed_revenue").split(',').map(field=>({field}));

	const { loading, error, data:messages }=useWarehouseQuery(
		{table,
			fields,
			conditions,
			order_by,
			limit:limit || 1000,
			skip:loadingConditions||!bots
		});
	if (loading){
		return "Loading...";
	}
	if (error) {
		console.error("Error:",error);
		return "There was an error loading messages";
	}

	messages.forEach(m=>{
		m.search_terms=[m.label?.toLowerCase(),
			m.primary_source_code?.toLowerCase(),
			m.subject?.toLowerCase(),
			m.campaign_name?.toLowerCase()
		].filter(Boolean);
	});


	return <MessageListWithSearch messages={messages}/>;
}


function MessageHome(){
	const account_id=useAccountId();
	const location = useLocation();
	const hash = queryString.parse(location.hash);
	const {bots,loading,error}=useBots();
	let default_query={
		start:"-30d"
	};

	if (loading || error) return loading || error;

	return <React.Fragment>
		<Header
			primary={[
				"Messages",
				<MessageFilters key="filters" default_query={default_query}/>,
				<NavLink key="ss" to={"/app/"+account_id+"/messages/sheet"}>
					<span className="nav-text">Spreadsheet</span>
				</NavLink>
			]}
		/>
		<div className="app-main-content-ltr">
			<div id="sidebar2" className="sidebar2-wide">
				<MessageList bots={bots} default_query={default_query}/>
			</div>
			<div className="app-main-content">
				{hash.message_id?<Message account_id={account_id} message_id={hash.message_id} bots={bots}/>:<MessageSummary bots={bots}/>}
			</div>
		</div>
	</React.Fragment>;
}
export default function(){
	return <Routes>
		<Route path='*' element={<MessageHome/>}/>
		<Route path='sheet'element={<MessageSheet/>}/>
		<Route path='source_codes'element={<MessageSourceCodes/>}/>
	</Routes>;
}
