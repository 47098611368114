import React from 'react';
import {humanize} from "../formatters";
/*
import NewJobSelector from '../dataflow/editor/NewJobSelector';
import {AdminOnly} from '../../AdminCheck';
import { useApolloClient } from '@apollo/client';

const addJobMutation = gql(`mutation addJob($job: JobListNewJob!,$job_list_id:String!) {
	job_list_add_job(job: $job,job_list_id:$job_list_id) {
		_id
	}
}`);
*/

import gql from "graphql-tag";
import {fraktureQuery} from '../FraktureQuery';
import {IsAdmin} from '../../AdminCheck';
import {NavLink} from 'react-router-dom';

import JobStatusIcon from './JobStatusIcon';
import List from '@mui/material/List';
import {SidebarListHeader,SidebarListItem} from '../components/SidebarList';
import {ArchiveButton,UnarchiveButton} from './JobButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose} from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import {useMultiaccount} from './';

import {useNavigateToJobList} from './JobModal';


const jobListFragment=`{
	_id
	account_id
	dataflow_id
	label
	last_update{
		ts
		status
	}
	modified_at
	status
	jobs{
		_id
		label
		bot_id
		bot{
			_id
			label
			path
			definition{
				_id
				metadata {
					logo
				}
			}
		}
		errors
		submodule
		method
		resolved_options
		start_after_timestamp
		start_after_job{
			_id
			label
		}
		status
		metrics{
			http_counter
			sql_counter
			records
			runtime
		}
	}
}`;

const JOBLIST_DETAIL_QUERY=gql(`
  query JOBLIST_DETAIL_QUERY($_id: ID!) {
	job_list(_id: $_id) ${jobListFragment}
  }
`);

function JobSmall({job,onClick,selected}){
	let detail=job.status;
	if (job.status==='complete' && job.metrics){
		//detail=<span className="badge badge-success badge-pill">{humanize(j.metrics.records)||0}</span>;
		detail=(humanize(job.metrics.records)||0) + " records";
	} else if(job.errors.length) {
		detail=<JobStatusIcon key="1" status={"error"}/>;
	}
	return <SidebarListItem
		className={`status-${job.status}`}
		selected={selected}
		onClick={onClick}
		primary={[job.label].filter(Boolean).join(".")}
		footer={[job.submodule||"",detail]}
	/>;
}

function JobListDetail(props) {
	let closeButton=<FontAwesomeIcon onClick={()=>props.closeModal()} icon={faClose}/>;
	//const client = useApolloClient();
	const navigateToJobList = useNavigateToJobList();
	const multiaccount=useMultiaccount();
	const {job_list_id,selected_id}=props;
	if (!job_list_id) return "";

	return fraktureQuery({
		query:JOBLIST_DETAIL_QUERY,
		variables:{_id:job_list_id},
	}, data => {
		if (!data.job_list) return "";

		const {account_id,status,dataflow_id}=data.job_list;
		let jobs=data.job_list.jobs;
		let l=data.job_list;
		let archiveButton;
		if(l.archived) archiveButton=<UnarchiveButton getJobListIds={e=>{return [l._id];}}/>;
		else archiveButton =<ArchiveButton getJobListIds={e=>{return [l._id];}}
			onCompleted={()=>{props.closeModal();}}
		/>;

		return <IsAdmin>
			{(isAdmin) => {
				let link=l.label;
				if (dataflow_id){
					if (isAdmin){
						link=<NavLink to={`/app/${account_id}/dataflow/${dataflow_id}`}>{l.label}</NavLink>;
					}else{
					}
				}
				return <>
					<SidebarListHeader
						closeButton={closeButton}
						primary={link}
						secondary={[l.last_update?dayjs(l.last_update.ts).format("MMM D, hh:mm a"):null,
							multiaccount?<NavLink to={`/app/${account_id}/job`}>{account_id}</NavLink>:null,
							<JobStatusIcon key="0" status={status}/>,
							archiveButton
						]
						}
					/>
					<List className="p-0">
						{(jobs||[]).map((job,i)=> {
							return <JobSmall job={job} key={i} selected={selected_id===job._id} onClick={e=>navigateToJobList({
								job_list_id:l._id,
								job_id: job._id
							})}/>;
						})}
					</List>
					{/* I don't think this is used, and it loads the bots all the time
					<AdminOnly>
						<NewJobSelector account_id={account_id} addJob={settings => {
							const {bot_id,submodule,method}=settings;
							if(!bot_id || !method) throw new Error('Need at least bot_id and method');
							client.mutate({mutation:addJobMutation,variables:{job_list_id,job: {bot_id,submodule,method}}});
						}}/>
					</AdminOnly>
					*/}
				</>;
			}}</IsAdmin>;
	});
};

export default JobListDetail;
export {JobSmall};
