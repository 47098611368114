import React from 'react';

import {Header} from '../components/Header';
import {Link} from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import {useAccountDetails,useAccountTypes} from '../account/AccountInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare} from '@fortawesome/free-solid-svg-icons';


/*
function DataflowStatus(props){
	if (true) return "";
	const {
		dataflow_count,
		scheduled_dataflows,
		unscheduled_dataflows,
		errored_dataflows
	}=props.account.metrics||{};

	if(!dataflow_count) return "Dataflows: 0";

	return <div style={{textAlign:"right"}}>
		<br/> {scheduled_dataflows?<span className="badge badge-pill text-white badge-green bg-green">
			{scheduled_dataflows}&nbsp;
			<span className="zmdi zmdi-check" />
		</span>:""}
		{errored_dataflows?<span className="badge badge-pill text-white badge-red bg-red">
			{errored_dataflows}&nbsp;
			<span className="zmdi zmdi-close" />
		</span>:""}{unscheduled_dataflows?<span className="badge badge-pill text-white badge-grey bg-grey">
			{unscheduled_dataflows}&nbsp;?
		</span>:""}
	</div>;
}
*/

function ChildCard({account}){
	let {_id,name,type,children=[]}=account;
	const types=useAccountTypes();
	let active=children.filter(d=>!d.disabled).length;
	let inactive=children.filter(d=>d.disabled).length;
	return <Card style={{minHeight:"70px"}} className="h-100">
		<CardHeader
			title={<Link to={`/app/${_id}`}><h3>{name}</h3></Link>}
			action={<Link to={`/app/${_id}`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faUpRightFromSquare}/></Link>}
		/>
		<CardContent>
			{active?<div key='active'>{active} active</div>:''}
			{inactive?<div key='inactive'>{inactive} inactive</div>:''}

		</CardContent>
		<CardActions><i>{types.find(x=>x.value===type)?.name || "Unknown"}</i></CardActions>
	</Card>;
}

export default function({parent_id}){
	const {account,loading,error}=useAccountDetails();
	if (loading) return loading;
	if (error) return error;
	let {_id,name,children:_children}=account;

	let children=_children.slice().sort((a,b)=>a.name<b.name?-1:1);
	let totalActive=children.reduce((active,{disabled,children:subchildren})=>{
		if (disabled) return active;
		active+=1;
		active+=(subchildren||[]).filter(d=>!d.disabled).length;
		return active;
	},0);
	let directActive=children.filter(d=>!d.disabled).length;
	//let directInactive=children.filter(d=>d.disabled).length;

	return <div className="app-main-content-wrapper">
		<Header
			primary={[
				<h1 key="name">{name}</h1>,
				<span key='active'>{totalActive} total active</span>,
				<span key='direct_active'>{directActive} direct active</span>,
				<span key="id">{_id}</span>
			]}
		/>
		<div className="app-main-content">
			<Grid container spacing={2}>
				{children.filter(d=>!d.disabled).map((child,i)=><Grid item xs={3} key={i}><ChildCard account={child}/></Grid>)}
			</Grid>
			{/*
			<h1>Disabled</h1>
			<Grid container spacing={2}>
				{children.filter(d=>d.disabled).map((child,i)=><Grid item xs={2} key={i}><ChildCard account={child}/></Grid>)}
			</Grid>
			*/}
		</div>
	</div>;
}









